export const apiKeys = {
  MAPBOX_ACCESS_TOKEN:
    "pk.eyJ1IjoiYXR1cjI0IiwiYSI6ImNsOW9wejNrcTBpamgzdmw4eGY4emtzbG4ifQ.6CabKWe9pgePiaHzESjAhw",
  GMAP_API_Key: "AIzaSyBU16E1Zmvvi3wP3gYK2ucjY53c9_7PZI4",
  GOOGLE_CLIENT_ID:
    "1016234628057-8k1j2bkcrvm4868taeb78oj86t6mb4td.apps.googleusercontent.com",
  LOCATION_FILTER_KEY:
    "am9BMTlaczIzaEw0alVabXBBcWZpRkJibm03UmZUSmMxMmU4dHZXMg==",
};

//27596888111-5v1ttdcgl9gqvtlh455900hrjehsih2k.apps.googleusercontent.com
