import { useEffect, useState } from "react";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Divider,
  Box,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";
import { fetchSideMenu } from "../features/general/generalSlice";
import DraftsIcon from "@mui/icons-material/Drafts";
import React from "react";
import { NavLink } from "react-router-dom";
import StarIcon from "../icons/star-icons";
import SendNotificationsIcon from "../icons/send-notifications";
import BlogWritersIcon from "../icons/invite-blogWritersIcon";
import InviteSupportIcon from "../icons/invite-support";
import NgoGroupIcon from "../icons/ngoGroupIcon";
import VideoRequest from "../icons/video-request";
import BlogList from "../icons/blog-list";
import FlaggedPost from "../icons/flagged-post";
import InviteContentModerator from "../icons/invite-content-moderater";
import VideoEditorsIcon from "../icons/invite-videoEditors";
import { setClearData } from "../features/super-admins/superAdminSlice";

const SideMenu = () => {
  const sideMenu = useAppSelector((state) => state.general.sideMenu);
  const { userDetails } = useAppSelector((state) => state.user);

  return (
    <List
      sx={{
        width: "100%",
        maxWidth: 360,
        bgcolor: "transparent",
        paddingLeft: "24px",
        paddingTop: "33px",
      }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      <style>
        {`.active {
          color:#674BFF !important;
          background-color: #fff;
          border-radius:11px;
        }
        `}
      </style>

      {sideMenu.map((item, index) => (
        <>
          <ListItemButton
            key={item.id}
            component={NavLink}
            to={item.redirectUrl}
            sx={{ padding: "10px 12px" }}
          >
            <ListItemIcon sx={{ minWidth: "44px" }}>
              {item.label === "Invite admins" ? (
                <StarIcon />
              ) : item.label === "Send notifications" ? (
                <SendNotificationsIcon />
              ) : item.label === "Invite blog writers" ? (
                <BlogWritersIcon />
              ) : item.label === "Invite support" ? (
                <InviteSupportIcon />
              ) : item.label === "NGOs/Groups" ? (
                <NgoGroupIcon />
              ) : item.label === "Video uploads" ? (
                <VideoRequest />
              ) : item.label === "Blogs List" ? (
                <BlogList />
              ) : item.label === "Invite video editors" ? (
                <VideoEditorsIcon />
              ) : item.label === "Reported posts" ? (
                <FlaggedPost />
              ) : item.label === "Invite content moderators" ? (
                <InviteContentModerator />
              ) : (
                <DraftsIcon />
              )}
            </ListItemIcon>
            <Typography sx={{ fontSize: "15px" }}>{item.label}</Typography>
          </ListItemButton>
          {userDetails?.id == 1 && (
            <>{index === 4 && <Box sx={{ height: 24 }} />} </>
          )}
          {userDetails?.id == 2 && (
            <>{index === 3 && <Box sx={{ height: 24 }} />} </>
          )}
        </>
      ))}
    </List>
  );
};

export default SideMenu;
