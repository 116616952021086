import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { setupAxiosInterceptors } from "./api/axios";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./app/rootReducer";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./utils/theme";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { apiKeys } from "./utils/api-keys";
import initializeGA from "./ga";

const handleUnauthenticated = () => {
  console.log("User is unauthenticated. Redirecting to login...");
};

setupAxiosInterceptors(handleUnauthenticated);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const clientId = apiKeys?.GOOGLE_CLIENT_ID;

initializeGA();

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <GoogleOAuthProvider clientId={clientId}>
      <ThemeProvider theme={theme}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </ThemeProvider>
    </GoogleOAuthProvider>
  </Provider>
  // </React.StrictMode>
);

reportWebVitals();
